[class^="icon-"]::before,
[class*=" icon-"]::before {
  position: relative;
  top: -1px;
  font-family: "starbase"; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1.2em;
  margin-left: 0.25em;
  font-size: 150%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-circlethin::before {
  content: '\f1db';
}

.icon-delete::before {
  content: '\f15f';
}

.icon-digitalid::before {
  content: '\f104';
}

.icon-document::before {
  content: '\f147';
}

.icon-dotshorz::before {
  content: '\f16f';
}

.icon-dotsvert::before {
  content: '\f126';
}

.icon-downopen::before {
  content: '\e800';
}

.icon-ethercircle::before {
  content: '\e8a4';
}

.icon-ethereum::before {
  content: '\e945';
}

.icon-exchange::before {
  content: '\f0ec';
}

.icon-file-pdf::before {
  content: '\f1c1';
}

.icon-form::before {
  content: '\f15e';
}

.icon-link-ext::before {
  content: '\f08e';
}

.icon-link::before {
  content: '\e80a';
}

.icon-login::before {
  content: '\e806';
}

.icon-okcircle::before {
  content: '\e802';
}

.icon-okcircled::before {
  content: '\e801';
}

.icon-hourglass::before {
  content: '\e8a7';
}

.icon-supporters::before {
  content: '\e8a8';
}

.icon-pencil::before {
  content: '\e805';
}

.icon-profile::before {
  content: '\f171';
}

.icon-purse::before {
  content: '\f159';
}

.icon-right-1::before {
  content: '\e809';
}

.icon-right-big::before {
  content: '\e807';
}

.icon-right::before {
  content: '\f178';
}

.icon-spin1::before {
  content: '\e831';
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.icon-spin2::before {
  content: '\e834';
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.icon-spin3::before {
  content: '\e838';
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.icon-spin4::before {
  content: '\e839';
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.icon-star::before {
  content: '\e944';
}

.icon-switch::before {
  content: '\e808';
}

.icon-th-large::before {
  content: '\e804';
}

.icon-th-list::before {
  content: '\e803';
}

.icon-warning::before {
  content: '\f121';
}

.icon-cancel-circled::before {
  content: '\e80a';
}

.icon-edit::before {
  content: '\e80b';
}

.icon-chat::before {
  content: '\e80c';
}

.icon-chat-empty::before {
  content: '\f0e6';
}

.icon-cog::before {
  content: '\e80d';
}

.icon-wrench::before {
  content: '\e80e';
}

.icon-info-circled::before {
  content: '\e80f';
}

.icon-help-circled::before {
  content: '\e810';
}

.icon-info::before {
  content: '\f129';
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
